import React, { useEffect, useMemo, useState } from 'react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { differenceInDays } from 'date-fns';
import {
  SortingState,
  flexRender,
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { Job } from '../models/job';
import { usePagination } from '../utils/pagination';
import { useAxios } from '../contexts/axios';
import { DeleteJobPosting, GetJobPostings } from '../services/job-postings';
import ConfirmDeleteModal from './jobs/ConfirmDeleteModal';
import JobSurveyModal from './jobs/JobSurveyModal';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import MobilePreview from './jobs/MobilePreview';
import clsx from 'clsx';

const DropdownMenu = ({ job, onClick, open }: { job: Partial<Job>; onClick: () => void; open: boolean }) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCloseSurvey, setShowCloseSurvey] = useState(false);
  const { axios } = useAxios();

  return (
    <Menu as="div" className="inline-block text-left">
      {showConfirmDelete ? (
        <ConfirmDeleteModal
          job={job}
          onClose={() => setShowConfirmDelete(false)}
          onConfirm={() => {
            DeleteJobPosting(axios, job.id);
            setShowConfirmDelete(false);
            setShowCloseSurvey(true);
          }}
        />
      ) : null}
      {showCloseSurvey ? <JobSurveyModal job={job} onClose={() => setShowCloseSurvey(false)} /> : null}
      <div>
        <Menu.Button
          className="inline-flex w-full justify-center rounded-full px-2 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          onClick={() => {
            onClick();
          }}
        >
          <EllipsisHorizontalIcon className="h-5 w-5" />
        </Menu.Button>
      </div>
      {open && (
        <Menu.Items
          static
          className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="px-1 py-1">
            <Menu.Item>{() => <MobilePreview job={job} isDropDown />}</Menu.Item>
            <Menu.Item>
              {() => (
                <Link
                  className={'hover: flex flex-row text-sm hover:bg-gray-100'}
                  to={`/jobs/${job.id}/edit`}
                  state={{ initJob: job }}
                >
                  <PencilIcon className="mx-2 h-5 w-5" />
                  <p>Edit</p>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900`}
                  onClick={() => setShowConfirmDelete(true)}
                >
                  <TrashIcon className="mr-2 h-5 w-5" />
                  Delete
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      )}
    </Menu>
  );
};

function DashboardTable() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { pagination, onPaginationChange, page, limit } = usePagination();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedJob, setSelectedJob] = useState<Partial<Job>>({});
  const { axios } = useAxios();

  useEffect(() => {
    GetJobPostings(axios, page + 1, limit).then(
      (res) => {
        const count = Number(res.headers['x-total']);
        setJobs(res.data);
        setTotal(count);
        setPageCount(Math.round(count / limit));
      },
      (err) => {
        console.error(err);
      }
    );
  }, [limit, page]);

  const data = jobs;

  const columns: ColumnDef<Job>[] = useMemo(
    () => [
      {
        header: 'Current Job Postings',
        columns: [
          {
            id: '0',
            accessorKey: 'title',
            header: 'Job Title',
            cell: (info) => info.getValue(),
          },
          {
            id: '1',
            accessorKey: 'created_at',
            header: 'Days Used',
            cell: (info) => {
              const today = new Date();
              return differenceInDays(today, new Date(info.getValue()));
            },
          },
          {
            id: '2',
            accessorKey: 'stats',
            header: 'Matches',
            cell: (info) => info.getValue().matches,
          },
        ],
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    pageCount,
    manualPagination: true,
    onSortingChange: setSorting,
    onPaginationChange,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //
    debugTable: false,
  });

  return (
    <div className="row-span-2 h-[72.5vh] overflow-x-scroll rounded-xl bg-white p-6">
      <div className="flex justify-between">
        <h2 className="text-lg font-medium">Current Job Postings</h2>
        <Link to="/jobs" className="font-medium text-primary">
          View All
        </Link>
      </div>

      {/* React Table */}
      {data.length == 0 ? (
        <h2 className="mt-16 grid w-full place-items-center text-left text-lg font-medium">
          You don&apos;t have any Job Posts
        </h2>
      ) : (
        <table className="mt-3 w-full">
          <thead className="bg-subtitle/20">
            {table
              .getHeaderGroups()
              .slice(1)
              .map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="[&>th]:px-4 [&>th]:py-5 [&>th]:text-left [&>th]:text-lg [&>th]:font-medium"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none flex justify-center items-center'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            <div className="text-lg font-medium">{header.column.columnDef.header as string}</div>
                            {
                              {
                                asc: <ChevronUpIcon className="flex h-6 cursor-pointer fill-black px-3" />,
                                desc: <ChevronDownIcon className="flex h-6 cursor-pointer fill-black px-3" />,
                              }[header.column.getIsSorted() as string]
                            }
                          </div>
                        )}
                      </th>
                    );
                  })}
                  <th className="items-center justify-center"></th>
                </tr>
              ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, 10)
              .map((row) => {
                return (
                  <tr
                    key={row.id}
                    className={clsx(
                      'relative border-b border-subtitle/10 [&>td]:px-4 [&>td]:py-4',
                      selectedJob === jobs[Number(row.id)] ? 'z-[1]' : 'z-[0]'
                    )}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    })}
                    <td>
                      <DropdownMenu
                        job={jobs[Number(row.id)]}
                        onClick={() => {
                          const job = jobs[Number(row.id)];
                          setSelectedJob(job === selectedJob ? {} : job);
                        }}
                        open={jobs[Number(row.id)] === selectedJob}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {/* Page Box */}
      {total <= limit ? null : (
        <div className="mt-7 flex justify-center">
          <div className="flex w-80 justify-evenly rounded-xl border-2 border-subtitle">
            <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
              <ChevronDoubleLeftIcon className="h-11 cursor-pointer border-r-[1px] border-subtitle fill-subtitle p-3" />
            </button>
            <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
              <ChevronLeftIcon className="h-11 cursor-pointer border-x-[1px] border-subtitle fill-subtitle p-3" />
            </button>

            <select
              className="h-11  border-x-[1px] border-y-0 text-primary"
              name=""
              id=""
              onChange={(e) => table.setPageIndex(Number(e.target.value))}
              value={table.getState().pagination.pageIndex}
            >
              {Array.from(Array(table.getPageCount()).keys()).map((page) => (
                <option value={page} key={page}>
                  {page + 1}
                </option>
              ))}
            </select>
            <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
              <ChevronRightIcon className="h-11 cursor-pointer border-x-[1px] border-subtitle fill-subtitle p-3 " />
            </button>
            <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
              <ChevronDoubleRightIcon className="h-11 cursor-pointer border-l-[1px] border-subtitle fill-subtitle p-3" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardTable;
